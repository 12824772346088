<template>
  <div class="processManage">
    <!-- 应用检测 -->
    <div>
      <navi-gation />
      <div class="one" v-show="isHidden == 1">
        <el-row :gutter="4" class="mt15" style="display: flex">
          <el-col :span="3">
            <el-input v-model.trim="queryObj.name" size="small" :placeholder="$t('AppManage.Appdetection.PleaseApp')" @keyup.enter.native="searchList"></el-input>
          </el-col>
          <el-col :span="4">
            <el-button size="small" type="primary" @click="searchList">{{$t("public.Inquire")}}</el-button>
          </el-col>
        </el-row>
        <el-row style="padding: 0px 10px 10px 10px" :gutter="4">
          <el-col :span="24">
            <el-button-group style="display: flex">
              <el-button @click="isHidden = 2" type="primary" size="small">{{$t("AppManage.Appdetection.UploadAndroidApp")}}</el-button>
              <el-button @click="isHidden = 3" type="primary" size="small">{{$t("AppManage.Appdetection.UploadiOSApp")}}</el-button>
              <el-button v-if="buttonJiance" @click="testShop" :disabled="btnJiance" :type="typeInfo4" size="small">{{$t("AppManage.Appdetection.SafetyScan")}}</el-button>
              <el-button v-else @click="testShops" :disabled="btnJiance" :type="typeInfo4" size="small">{{$t("AppManage.Appdetection.SafetyScan")}}</el-button>
              <el-button :disabled="hasSeleted || deletesBtn" :type="typeInfo2" size="small" @click="deletes" style="margin-left: 1px">{{$t('public.Delete')}}</el-button>
            </el-button-group>
          </el-col>
        </el-row>
        <el-row class="formBox" :gutter="16" style="padding: 0 20px">
          <el-table :cell-style="{padding:'3px 0px'}" :row-key="getRowKeys" :header-row-style="{height:'30px'}" stripe ref="multipleTable" :data="dictData" @selection-change="handleSelectionChange" @sort-change="sort" :default-sort="{ prop: 'lastupdatetime', order: 'descending' }" class="tableClass" style="width: 100%" size="mini">
            <el-table-column header-align="center" align="center" type="selection" width="60" :reserve-selection="true">
            </el-table-column>
            <el-table-column header-align="left" align="left" prop="appName" :label="$t('public.ApplicationName')" width="160" sortable="custom" :show-overflow-tooltip="true">
              <template v-slot="{ row }">
                <el-link @click="nameDetail(row)" type="warning" :underline="false" style="color: #d38a08;font-size: 12px;">
                  <span style="color: #d38a08">{{ row.appName }}</span>
                </el-link>
              </template>
            </el-table-column>
            <el-table-column header-align="left" align="left" prop="versionName" :label="$t('public.Version')" width="80" sortable="custom" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column header-align="left" align="left" prop="appSizeStr" :label="$t('public.Size')" width="80" sortable="custom" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column :label="$t('AppManage.Appdetection.Scan')" sortable="custom" width="110" align="left" header-align="left" prop="scanScore" :show-overflow-tooltip="true">
              <template v-slot="{ row }">
                <template v-if="row.scanScore == -2"> {{ $t('AppManage.Appdetection.NotDetected') }} </template>
                <template v-if="row.scanScore == -1"> {{ $t('AppManage.Appdetection.Detecting') }} </template>
                <template v-if="row.scanScore >= 0"> {{ row.scanScore }}{{ $t('AppManage.Appdetection.Divide') }} </template>
              </template>
            </el-table-column>
            <!-- 注释部分是6.1.0需求  （应用检测改为安全检测） -->
            <!-- <el-table-column label="安全检测" sortable="custom" width="110" align="left" header-align="left" prop="scanScore" :show-overflow-tooltip="true">
              <template v-slot="{ row }">
                <template v-if="row.scanScore == -2"> 未检测 </template>
                <template v-if="row.scanScore == -1"> 检测中 </template>
                <template v-if="row.scanScore >= 0"> {{ row.scanScore }}分 </template>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="合规检测" width="110" align="left" header-align="left" prop="scanScore" :show-overflow-tooltip="true">
              <template v-slot="{ row }">
                <template v-if="row.complianceStatus == -2"> 未检测 </template>
                <template v-if="row.complianceStatus == 1"> 检测中 </template>
                <template v-if="row.complianceStatus == 2"> 合规 </template>
                <template v-if="row.complianceStatus == 3"> 风险 </template>
                <template v-if="row.scanScore >= 0"> {{ row.scanScore }} </template>
              </template>
            </el-table-column> -->
            <el-table-column header-align="left" align="left" prop="osTypeId" :label="$t('public.OperatingSystem')" width="110" sortable="custom">
              <template v-slot="{ row }">
                <template v-if="row.osTypeId == 20"> Android </template>
                <template v-if="row.osTypeId == 10"> iOS </template>
              </template>
            </el-table-column>
            <el-table-column header-align="left" align="left" prop="groupFullName" :label="$t('public.Section')" width="90" sortable="custom" :show-overflow-tooltip="true">
              <template v-slot="{ row }">
                <!-- {{ row.groupFullName }} -->
                <span v-if="row.groupFullName">
                  {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="lastupdatetime" :label="$t('public.ModificationTime')" width="180" sortable="custom" header-align="left" align="left" :show-overflow-tooltip="true">
              <template v-slot="{ row }">{{ row.lastupdatetime | dateformat }}</template>
            </el-table-column>
            <el-table-column :label="$t('public.Controls')" header-align="left" align="left">
              <template v-slot="{ row }">
                <span class="action_icon" v-if="row.scanScore >=0">
                  <img @click="safeStrength(row, row.id)" :title="$t('AppManage.Appdetection.SafetyScan')" src="@/images/icon_check.png" alt="" style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                " />
                </span>
                <span class="action_icon" v-else-if="row.scanScore == -2">
                  <img @click="safeStrengths(row, row.id)" :title="$t('AppManage.Appdetection.SafetyScan')" src="@/images/icon_check.png" alt="" style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                " />
                </span>
                <span class="action_icon">
                  <img @click="bbb(row)" src="@/images/icon_download.png" :title="$t('public.Download')" alt="" style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                " />
                </span>
                <span class="action_icon">
                  <!-- v-if="row.appStatus !== 2" -->
                  <img @click="deletes(row)" src="@/images/icon_del.png" alt="" :title="$t('public.Delete')" style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                " />
                </span>
              </template>
            </el-table-column>
          </el-table>
          <pagination :page="queryObj.pageNumber" :limit="queryObj.rowNumber" :total="total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
        </el-row>
      </div>
      <shopAEditor v-if="isHidden == 2" @haldesotr="haldesotr" :androidData="androidData" />
      <shopIEditor v-if="isHidden == 3" @haldesotr="haldesotr" :androidData="androidData" />
      <shoptest v-if="isHidden == 4" :dataArray="dataArray" :scanScore="dataArray.scanScore" :sidd="dataArray.id" @haldesotr="haldesotr3"></shoptest>
      <ios-test v-if="isHidden == 6" :dataArray="dataArray" :scanScore="dataArray.scanScore" :sidd="dataArray.id" @haldesotr="haldesotr3"></ios-test>
      <shopstrong v-if="isHidden == 5" @haldesotr="haldesotr1" :sidd="dataArray.id" :androidData="dataArray"></shopstrong>
      <compliance @goBackSelection='goBackSelection' :dataArray="dataArray" v-if="isHidden == 7" />
    </div>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
    <!-- 名称详情 -->
    <Eldialog @handleClose="handleClose" :title="title" :dialogVisible="dialogVisible" @heightCustom="heightCustom" @determine="determine" :flagbtn="false">
      <namedetail @aaa="handleClose" :dictData="dataFrom" />
    </Eldialog>
    <!-- 加固详情 -->
    <Eldialog class="dialog" @handleClose="handleClose" :title="$t('AppManage.Appreinforce.Reinforcement')" :dialogVisible="jiaGuVisible" @heightCustom="heightCustom">
      <el-row style="width: 100%" :gutter="16">
        <el-col style="padding: 0 40px" v-for="(item, index) in jiaGlist" :key="index" class="clo24" :span="24">
          <div v-if="item.name == $t('AppManage.Appreinforce.SOfile')">
            <el-checkbox v-model="checksign_type">{{ item.name }}</el-checkbox>
            <p style="padding: 10px 30px; color: #000" v-for="(item, i) in item.children" :key="i">
              {{ item }}
            </p>
          </div>
          <div v-else>
            <el-checkbox v-model="checksign_type">{{ item.name }}</el-checkbox>
            <p style="padding: 10px 30px; color: #000" v-if="item.children">
              {{ item.children }}
            </p>
          </div>
        </el-col>
      </el-row>
    </Eldialog>
    <!-- 下载弹框 -->
    <!-- <Eldialog @handleClose="handleClose" :title="'下载应用'" :dialogVisible="downFlag">
      <el-row style="width: 100%" :gutter="8">
        <el-col class="col12" :span="12">
          <el-button @click="downxiazai" type="primary">原始应用</el-button>
        </el-col>
        <el-col class="col12" :span="12">
          <el-button :disabled="!statusDown" @click="downxiazai1" type="primary"
            >加固应用</el-button
          >
        </el-col>
      </el-row>
    </Eldialog> -->
  </div>
</template>
<script>
const Base64 = require('js-base64').Base64
import Eldialog from '@/components/elDialog'
// 详情
import namedetail from './components/safetyAppDetail.vue'
// ios 编辑
import shopIEditor from './copentUps/shopIEditor.vue'
// Android  编辑
import shopAEditor from './copentUps/shopAEditor.vue'
// 应用检测
import shoptest from './components/shoptest.vue'
// 应用加固
import shopstrong from './components/shopstrong.vue'
import dialogInfo from '@/components/promptMessage/index'
import naviGation from '@/components/hearder/index'
import pagination from '@/components/pagination/page.vue'
import IosTest from './components/iosTest.vue'
// 合规检测
import Compliance from '@/components/essentialinformation/index.vue'
export default {
  components: {
    pagination,
    naviGation,
    dialogInfo,
    Eldialog,
    namedetail, //名称详情弹框
    shopAEditor, //Android编辑
    shopIEditor, //IOS编辑
    shopstrong, //应用加固
    shoptest,
    IosTest, //应用检测
    Compliance //合规检测
  },
  data() {
    return {
      vueconfig: vueConfig.jqUrl.slice(0, vueConfig.jqUrl.length - 1),
      buttonJiance: true,
      groupFullNameShowByIndex: '',
      pathImg: '',
      activeName: 'first',
      installFlag: false,
      tableAn: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ],
      tableAn1: [],
      checksign_type: true,
      path: '',
      downFlag: false,
      checked: true,
      isHidden: 1, // 显示当前 / 编辑页面
      androidData: {}, //子组件--Android编辑
      iosData: {}, //子组件--ios编辑
      typeInfo: 'info',
      typeInfo1: 'info',
      typeInfo2: 'info',
      typeInfo3: 'info',
      typeInfo4: 'info',
      typeInfo5: 'info',
      typeInfo6: 'info',
      typeInfo7: 'info',
      btnJiagu: true,
      rulesHTML: 'dasdasd',
      num: 220,
      jiaGuVisible: false,
      flagbtn: false, //footer
      downreport: true,
      GRCJiance: true,
      options1: [
        {
          value: '',
          label: '安装类型'
        },
        {
          value: '1',
          label: '普通应用'
        },
        {
          value: '2',
          label: '必装应用'
        }
      ],
      options2: [
        {
          value: '',
          label: '防护类型'
        },
        {
          value: '1',
          label: '未做防护'
        },
        {
          value: '0',
          label: '安全沙箱'
        },
        {
          value: '3',
          label: '安全加固'
        },
        {
          value: '2',
          label: '加固沙箱'
        }
      ],
      options3: [
        {
          value: '',
          label: '应用状态'
        },
        {
          value: '1',
          label: '未上架'
        },
        {
          value: '2',
          label: '已上架'
        },
        {
          value: '3',
          label: '已下架'
        }
      ],
      // 提示框的提示语
      titleInfo: '',
      // applyFlag: false, //选择应用
      // statusVisible: false, //状态弹框
      title: '应用详情', //应用详情title
      title1: '应用编辑',
      visibaelFlag: false, //提示消息默认--关闭
      dialogVisible: false, //详情弹框默认--关闭
      dataFrom: {}, //应用详情组件
      // table表格数据
      dictData: [],
      multipleSelection: [],
      queryObj: {
        pageNumber: 1,
        rowNumber: 10,
        manage: 1,
        appType: '',
        preventScreenshot: '',
        statu: '',
        name: '',
        sort: '',
        orderColume: 'createtime',
        orderRule: 'DESC'
      },
      total: 0,
      // 遮罩全选
      btnJiance: true,
      isCheckShow: false,
      hasSeleted: true,
      theShelves: true,
      shelves: true,
      deletesBtn: true,
      dataArray: {},
      sidd: '',
      jiaGlist: [],
      statusDown: false,
      // 上传
      up: {
        type: 'protected',
        files: ['']
      },
      //上传文件参数
      fileList: [],
      //上传文件保存接口参数
      uploadFileParams: {
        // fileName: "",
        // size: "",
        // path: "", //（地址）
        appName: ''
      },
      form: {
        fileList: []
      },
      //传参数
      filePath: '' //文件存储地址
    }
  },
  created() {
    this.tableList() //应用商店table数据表格
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    // this.tableList(); //应用商店table数据表格
  },
  methods: {
    goBackSelection() {
      this.isHidden = 1
      this.$refs.multipleTables.clearSelection()
      this.tableList()
    },
    getRowKeys(row) {
      return row.id
    },
    safeStrengths(val) {
      this.$confirm(this.$t('AppManage.Appdetection.ApplyOk'),
        this.$t('public.PromptMessage'),
        {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
        type: 'warning',
        customClass: 'persdsd'
      })
        .then(async () => {
          let params = {
            ids: [val.id + '']
          }
          const res = await this.$axios.post(
            '/httpServe/entAppScan/send',
            params,
            true
          )
          console.log(res)
          if (res.code == 200) {
            // this.tabBass = true
            this.tableList()
          }
        })
        .catch(() => {
          return false
        })
    },
    testShops() {
      this.$confirm(this.$t('AppManage.Appdetection.ApplyOk'),
        this.$t('public.PromptMessage'),
        {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
        type: 'warning',
        customClass: 'persdsd'
      })
        .then(async () => {
          let data = []
          this.multipleSelection.forEach((item) => {
            data.push(item.id + '')
          })
          let params = {
            ids: data
          }
          const res = await this.$axios.post(
            '/httpServe/entAppScan/send',
            params,
            true
          )
          console.log(res)
          if (res.code == 200) {
            // this.tabBass = true
            this.$refs.multipleTable.clearSelection()
            this.tableList()
          }
        })
        .catch(() => {
          return false
        })
    },
    // 合规检测
    ComplianceTesting(row) {
      console.log(121312, '0000')
      console.log(row, 'valvalvalval')
      this.isHidden = 7
    },
    // 设置合规检测
    ComplianceTestings(row) {
      this.dataArray = row
      this.isHidden = 7
    },
    // 下载报告
    Downloadreport() {
      this.reportpdfAndDoc()
    },
    downloadFile(sdata) {
      let url = sdata
      url = Base64.decode(url)
      url = this.vueconfig + url
      console.log(url)
      window.open(url)
    },

    async callfudown(strArr) {
      console.log(strArr, '------')
      var param = {
        // path: res.data
        path: strArr
      }
      console.log(param, 'paramparamparamparam')
      const s = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      // debugger;
      console.log(s, 's.datas.datas.data')
      let sdata = s.data
      console.log(sdata, 'sdatasdatasdatasdata')
      this.downloadFile(sdata)
    },

    async reportpdfAndDoc() {
      this.multipleSelection.forEach((i) => {
        if (i.scanScore == -2 && i.complianceStatus == -2) {
          this.$message({
            message: this.$t('AppManage.Appdetection.Checkfirst'),
            type: 'error',
            offset: 100
          })
          return
        }
        // i.scanPath==null || i.compliancePath==null
        if (i.scanPath == null && i.compliancePath == null) {
          this.$message({
            message: this.$t('AppManage.Appdetection.Pleasewait'),
            type: 'error',
            offset: 100
          })
          return false
        }
        // i.compliancePath == null
        if (i.scanPath == null) {
          var strArrs = i.compliancePath.split(',')
          strArrs.forEach((j) => {
            console.log(strArrs, '1111---------')
            this.callfudown(j)
          })
          return
        }
        if (i.compliancePath == null) {
          var strArr = i.scanPath.split(',')
          strArr.forEach((j) => {
            console.log(strArr, '1111---------')
            this.callfudown(j)
          })
          return
        } else {
          var strArrs = i.compliancePath.split(',')
          strArrs.forEach((j) => {
            console.log(strArrs, '1111---------')
            this.callfudown(j)
          })
          var strArr = i.scanPath.split(',')
          strArr.forEach((j) => {
            console.log(strArr, '1111---------')
            this.callfudown(j)
          })
          return
        }
      })
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px";
    },
    // 下载
    async bbb(row) {
      var params = {
        // type: "protected",
        path: row.path
      }
      // console.log(params, "原始文件参数");
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        params,
        true
      )
      // console.log(res, "下载原始文件");
      // window.open(res.data);
      // let url = Base64.decode(res.data.data);
      if (res.data == null) {
        this.$message({
          message: this.$t('AppManage.Appdetection.Fileempty'),
          type: 'error',
          offset: 100
        })
        return false
      } else {
        await this.$axios.downloadFile(res.data)
      }
      this.downloadVisible = false //关闭选择下载弹框
    },
    //上传应用
    async uploadChange(file) {
      let fileName = file.name
      let suffix = file.name?.substring(fileName.lastIndexOf('.') + 1) //获取文件后缀名
      let suffixArray = ['apk', 'ipa']
      // console.log(suffix, "1111");
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: this.$t('AppManage.Appdetection.Fileformaterror'),
          type: 'error',
          duration: 100
        })
      } else {
        const form = new FormData()
        // 文件对象
        form.append('files', file.raw)
        form.append('type', this.up.type)
        form.append('fileType', suffix)
        form.append('moduleName', 'protected')
        const res = await this.$axios.upload(
          '/httpServe/sysFile/fileUp',
          form,
          this.updateProgress,
          true
        )
        this.uploadFileParams.appName = file.name
        // this.uploadFileParams.size = file.size;
        // console.log(res);
        // console.log(this.uploadFileParams);
        if (res.data.code == '200') {
          let path = res.data.data[0]
          this.save(path, fileName)
        } else {
          // this.visibaelFlag = true;
          // this.titleInfo = "上传文件成功";
          this.$message({
            message: this.$t('AppManage.Appdetection.UploadSuccess'),
            type: 'success',
            offset: 100
          })
          this.tableList()
        }
      }
    },
    updateProgress(e) {
       //e为回调回来的参数 通过进行和total的值来进行进度
    },
    async save(path, fileName) {
      this.path = path
      // console.log(this.path,"_+++++++++++++++++++");
      var params = {
        appName: fileName,
        path: path
      }
      // console.log(params);
      const res = await this.$axios.post(
        '/httpServe/purgeApp/save',
        params,
        true
      )
      // console.log(res);
      if (res.data == 1) {
        // this.visibaelFlag = true;
        // this.titleInfo = "应用上传成功";
        this.$message({
          message: this.$t('AppManage.Appdetection.Appsuccess'),
          type: 'success',
          offset: 100
        })
        this.tableList()
        return
      } else {
        // this.visibaelFlag = true;
        // this.titleInfo = "应用上传失败";
        this.$message({
          message: this.$t('AppManage.Appdetection.Apperror'),
          type: 'error',
          offset: 100
        })
        this.tableList()
      }
    },
    // 检测页面
    safeStrength(val, id, row) {
      this.dataArray = val
      this.dataArray.id = id
      if (this.dataArray.osTypeId == 20) {
        this.isHidden = 4
      } else {
        this.isHidden = 6
      }
      if (row.purgeStatus != 2) {
        return true
      } else {
        return false
      }
    },
    testShop() {
      // console.log(this.dataArray);
      if (this.dataArray.osTypeId == 20) {
        this.isHidden = 4
      } else {
        this.isHidden = 6
      }
    },
    selectEnable(row, rowIndex) {
      if (row.scanScore != -1) {
        return true
      } else {
        return false
      }
    },
    async azFenPei(row) {
      this.activeName = 'first'
      let params = {
        entAppId: row.id,
        deviceName: ''
      }
      this.titleInfo = row.appName + this.$t('AppManage.Appdetection.Instacondi')
      const res = await this.$axios.post(
        '/api/entApp/installDevice',
        params,
        true
      )
      // console.log(res);
      if (res.data) {
        this.tableAn1 = res.data.assign
        this.tableAn = res.data.install
      }
      this.installFlag = true
    },
    async downFile() {
      var param = {
        path: '/public/tools/SignTool.zip'
      }
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      // console.log(res);
      await this.$axios.downloadFile(res.data)
    },
    haldesotr() {
      this.$nextTick(() => {
        this.isHidden = 1
      })
      // this.titleInfo = "保存成功";
      // this.visibaelFlag = true;
      this.$message({
        message: this.$t('public.SuccessfullySaved'),
        type: 'success',
        offset: 100
      })
      this.searchList()
    },
    haldesotr1() {
      this.isHidden = 1
      this.searchList()
    },
    haldesotr3() {
      // this.searchList();
      // this.titleInfo = "正在检测应用请稍后...";
      // this.visibaelFlag = true;
      this.$message({
        message: this.$t('AppManage.Appdetection.Betesting'),
        type: 'success',
        offset: 100
      })
      this.isHidden = 1
      this.searchList()
    },
    // 分配
    jump2Detail: function (menu, param) {
      this.$axios.setOneTimeParam('menu', menu)
      this.$parent.$parent.$parent.$parent.$parent.clickMenu()
      if (param) {
        this.$axios.postMessage(param)
      }
    }, // 分配
    distribution(row) {
      this.jump2Detail('Delivery/Delivery', {
        param: {
          apps: [row.id]
        }
      })
    },
    distribution1() {
      let data = []
      this.multipleSelection.forEach((item, index) => {
        data.push(item.id)
      })
      this.jump2Detail('Delivery/Delivery', {
        param: {
          apps: data
        }
      })
    },
    sort(column) {
      if (column.prop == 'appName') {
        this.queryObj.orderColume = 'e.name'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'versionName') {
        this.queryObj.orderColume = 'e.versionName'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'appSizeStr') {
        this.queryObj.orderColume = 'e.appsize'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'scanScore') {
        this.queryObj.orderColume = 'ext.scan_score'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'osTypeId') {
        this.queryObj.orderColume = 'e.ostypeid'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'groupFullName') {
        this.queryObj.orderColume = 'g.groupFullName'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'lastupdatetime') {
        this.queryObj.orderColume = 'e.lastupdatetime'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      this.tableList()
    },
    async downxiazai() {
      var param = {
        path: this.path
      }
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      await this.$axios.downloadFile(res.data)
    },
    async downxiazai1() {
      // console.log(this.path)
      const strCopy = this.path.split('/')
      strCopy[strCopy.length - 1] = 'safe_' + strCopy[strCopy.length - 1]
      let data = strCopy.join('/')
      var param = {
        path: data
      }
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      await this.$axios.downloadFile(res.data)
    },
    // 下载
    async download(val) {
      this.downFlag = true
      this.path = val.path
      this.statusDown = val.purgeStatus == 1 ? true : false
    },
    jiaGuStatus(row) {
      this.jiaGlist = JSON.parse(row.purgeInfo).filter((item) => item.name)
      this.jiaGuVisible = true
    },
    //删除
    async deleteBaths(val) {
      if (val.id) {
        let data = []
        data.push(val.id)
        let param = {
          manage: 1,
          ids: data
        }
        const res = await this.$axios.post(
          '/httpServe/entApp/delete',
          param,
          true
        )
        // this.titleInfo = "删除成功";
        // this.visibaelFlag = true;
        this.$message({
          message: this.$t('public.SuccessfullyDeleted'),
          type: 'success',
          offset: 100
        })
        this.searchList()
        this.$refs.multipleTables.clearSelection()
      } else {
        // console.log(this.multipleSelection);
        let data = []
        this.multipleSelection.forEach((item, index) => {
          data.push(item.id)
        })
        let param = {
          manage: 1,
          ids: data
        }
        const res = await this.$axios.post(
          '/httpServe/entApp/delete',
          param,
          true
        )
        if (this.multipleSelection.length > 1) {
          // this.titleInfo = "批量删除成功";
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('public.SuccessfullyDeleteds'),
            type: 'success',
            offset: 100
          })
          this.searchList()
        } else {
          // this.titleInfo = "删除成功";
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('public.SuccessfullyDeleted'),
            type: 'success',
            offset: 100
          })
          this.searchList()
          this.$refs.multipleTables.clearSelection()
        }
      }
    },
    // 名称详情
    nameDetail(val) {
      // console.log(val, "-----名称详情");
      this.dialogVisible = true
      this.dataFrom = { ...val }
      // console.log(this.dataFrom, "this.dataform");
    },
    // 渲染数据列表
    async tableList() {
      var param = {
        currentPage: this.queryObj.pageNumber,
        pageSize: this.queryObj.rowNumber,
        orderColume: this.queryObj.orderColume,
        orderRule: this.queryObj.orderRule,
        type: '1',
        appName: this.queryObj.name,
        isGetH5App:0,
        isGetParserLose:0
      }
      console.log(param)
      // http95
      const res = await this.$axios.post(
        '/httpServe/entApp/getDataInfo',
        param,
        true
      )
      // console.log(res, "应用商店table表格数据");
      this.dictData = res.data.content
      this.total = res.data.total
    },
    // 查询
    searchList() {
      this.queryObj.pageNumber = 1
      this.tableList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = [...val]
      if (val.length == 0) {
        this.hasSeleted = true
        this.theShelves = true
        this.shelves = true
        this.deletesBtn = true
        this.btnJiance = true
        this.btnJiagu = true
        this.downreport = true
        this.GRCJiance = true
        this.typeInfo = 'info'
        this.typeInfo1 = 'info'
        this.typeInfo2 = 'info'
        this.typeInfo3 = 'info'
        this.typeInfo4 = 'info'
        this.typeInfo5 = 'info'
        this.typeInfo6 = 'info'
        this.typeInfo7 = 'info'
      } else {
        let dataArray = [...val]
        this.dataArray = dataArray.pop()
        this.typeInfo4 = 'primary'
        this.typeInfo5 = 'primary'
        this.typeInfo6 = 'primary'
        this.typeInfo7 = 'primary'
        this.downreport = false
        this.GRCJiance = false
        this.btnJiance = false
        this.btnJiagu = false
        // 下载报告
        if (this.multipleSelection.length > 10) {
          this.typeInfo6 = 'info'
          this.downreport = true
        }
        // 合规检测
        if (this.multipleSelection.length > 1) {
          this.typeInfo7 = 'info'
          this.GRCJiance = true
        }
      }
      if (this.multipleSelection.length == 1) {
        this.deletesBtn = false
        this.hasSeleted = false
        this.typeInfo2 = 'primary'
        this.buttonJiance = true
        if (this.multipleSelection[0].scanScore == -2) {
          this.buttonJiance = false
        }
        if (this.multipleSelection[0].scanScore == -1) {
          this.typeInfo4 = 'info'
          this.btnJiance = true
        }
      }
      if (this.multipleSelection.length >= 2) {
        this.buttonJiance = false
        this.deletesBtn = false
        this.hasSeleted = false
        this.typeInfo5 = 'info'
        this.typeInfo2 = 'primary'
        this.btnJiagu = true
        this.multipleSelection.forEach((item, index) => {
          if (this.multipleSelection[index].scanScore == -1) {
            this.typeInfo4 = 'info'
            this.btnJiance = true
          }
        })
      }
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.queryObj.pageNumber = page
      this.queryObj.rowNumber = limit
      // 调用查询方法
      this.tableList()
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false
    },
    //当前页全选
    curSelection() {
      this.disabled = false
      this.$refs.multipleTable.toggleAllSelection()
    },
    //所有页全选
    // toggleAllSelection() {
    //   this.disabled = false;
    //   this.$refs.multipleTable.toggleAllSelection();
    // },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true
          })
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
        this.disabled = false
      }
      this.isCheckShow = true
    },
    //删除
    deletes(val) {
      if (this.dataArray.complianceStatus == 1) {
        this.$message({
          message: this.$t('AppManage.Appdetection.Failedtodelete'),
          type: 'error',
          offset: 100
        })
        this.$refs.multipleTable.clearSelection()
        return false
      }
      this.$confirm(this.$t('AppManage.Appdetection.Selectedapp'),
        this.$t('public.PromptMessage'),
        {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(() => {
          this.deleteBaths(val)
        })
        .catch(() => {
          return false
        })
    },
    // 关闭
    handleClose() {
      this.visibaelFlag = false //提示消息  关闭
      this.dialogVisible = false //详情弹框  关闭
      this.jiaGuVisible = false //加固详情
      this.downFlag = false
      this.installFlag = false
    },
    heightCustom() {
      this.height.height = this.num
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
:v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: none;
}
.el-linkStyle {
  display: flex;
  cursor: pointer;
  color: #1e89e5;
  img {
    width: 20px;
    line-height: 20px;
    // margin-top: 20px;
  }
}
.mt15 {
  padding: 10px 10px;
}
.formBox {
  //全选弹框
  position: relative;
  .tableCheckBox {
    border: 1px solid #1e89e5;
    border-radius: 3px;
    width: 80px;
    background-color: #fff;
    position: absolute;
    top: 32px;
    left: 20px;
    z-index: 999;
    // background:rgba(255, 255, 255,.1)
    li {
      margin-top: -1px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #575656;
      cursor: pointer;
      font-weight: 400;
      font-family: Microsoft YaHei;
      font-size: 12px;
      list-style: none !important;
      div {
        display: inline-block;
      }
    }
    li:hover {
      color: #d38a08;
      text-decoration: underline;
    }
  }
}
.processManage .callenia {
  height: 0;
}
.clo24 {
  margin: 10px 0;
}
.col12 {
  text-align: center;
  margin: 60px 0;
  .el-button {
    width: 180px;
  }
}
::v-deep .el-checkbox__label {
  color: #000;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}
::v-deep .el-table__empty-text {
  line-height: 0px;
  width: 60px;
  color: #909399;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0px;
}
/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
  display: none !important;
}
</style>
